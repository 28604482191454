exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-gaurav-ganoo-js": () => import("./../../../src/pages/about/gaurav-ganoo.js" /* webpackChunkName: "component---src-pages-about-gaurav-ganoo-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-reema-ganu-js": () => import("./../../../src/pages/about/reema-ganu.js" /* webpackChunkName: "component---src-pages-about-reema-ganu-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-work-eskyshop-js": () => import("./../../../src/pages/work/eskyshop.js" /* webpackChunkName: "component---src-pages-work-eskyshop-js" */),
  "component---src-pages-work-healthyeats-js": () => import("./../../../src/pages/work/healthyeats.js" /* webpackChunkName: "component---src-pages-work-healthyeats-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-rooksys-js": () => import("./../../../src/pages/work/rooksys.js" /* webpackChunkName: "component---src-pages-work-rooksys-js" */),
  "component---src-pages-work-rytle-js": () => import("./../../../src/pages/work/rytle.js" /* webpackChunkName: "component---src-pages-work-rytle-js" */),
  "component---src-pages-work-siblu-js": () => import("./../../../src/pages/work/siblu.js" /* webpackChunkName: "component---src-pages-work-siblu-js" */),
  "component---src-pages-work-telma-js": () => import("./../../../src/pages/work/telma.js" /* webpackChunkName: "component---src-pages-work-telma-js" */),
  "component---src-pages-work-ucode-js": () => import("./../../../src/pages/work/ucode.js" /* webpackChunkName: "component---src-pages-work-ucode-js" */)
}

